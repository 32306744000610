const SETTING_PATH = {
  '/settings': '/settings',
  '/roles': '/roles',
  '/topics': '/topics',
  '/sounds': '/sounds',
  '/routes': '/routes',
  '/rules': '/rules',
  '/working-time': '/working-time',
  '/blacklist': '/blacklist',
  '/users': '/users',
  '/sip-trunks': '/sip-trunks',
  '/mail-sender': '/mail-sender',
};

const APPLICATION_PATH = {
  '/calls/dashboard': '/calls/dashboard',
};

const OTHER_PATH = {
  '/testing': '/testing',
  '/user-dashboard': '/user-dashboard',
};

type ApplicationPathKey = keyof typeof APPLICATION_PATH;
type SettingPathKey = keyof typeof SETTING_PATH;
type OtherPathKey = keyof typeof OTHER_PATH;

export type {ApplicationPathKey, OtherPathKey, SettingPathKey};
export {APPLICATION_PATH, OTHER_PATH, SETTING_PATH};
