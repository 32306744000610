import {
  APPLICATION_PATH,
  ApplicationPathKey,
  OTHER_PATH,
  OtherPathKey,
  SETTING_PATH,
  SettingPathKey,
} from './menuPath';

const getActiveMenu = (path: string) => {
  if (
    APPLICATION_PATH[path as ApplicationPathKey] ||
    path.includes('applications') ||
    path.includes('calls')
  ) {
    return 'applications';
  }

  if (SETTING_PATH[path as SettingPathKey]) {
    return 'setting';
  }

  if (path.includes('reports')) {
    return 'reports';
  }

  if (path.includes('handbooks')) {
    return 'handbooks';
  }

  if (path.includes('dispatcher-module')) {
    return 'dispatcherModule';
  }

  if (OTHER_PATH[path as OtherPathKey]) {
    return 'other';
  }

  if (path.includes('project')) {
    return 'project';
  }

  if (path.includes('crm')) {
    return 'crm';
  }

  if (path.includes('input-chanels')) {
    return 'input-chanels';
  }

  if (path.includes('output-chanels')) {
    return 'output-chanels';
  }

  if (path.includes('external-tools')) {
    return 'external-tools';
  }

  if (path.includes('internal-tools')) {
    return 'internal-tools';
  }

  if (path.includes('demo-settings')) {
    return 'demo-settings';
  }

  if (path.includes('dashboard')) {
    return 'dashboard';
  }
};

export {getActiveMenu};
